import "./App.css";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Peer from "peerjs";
import { v4 as uuid } from "uuid";
import Page404 from "./containers/Page404";
import * as freeice from "freeice";

const USECASES = {
  VIDEO_DOORBELL: "VIDEO_DOORBELL",
  VEHICLE_TAG: "VEHICLE_TAG",
  PET_TAG: "PET_TAG",
  LOST_AND_FOUND_TAG: "LOST_AND_FOUND_TAG",
  LUGGAGE_TAG: "LUGGAGE_TAG"
};

var globalVar = 0;

function MainApp() {
  const Ref = useRef(null);

  const [timer, setTimer] = useState("00:00");
  const [startTime, setStartTime] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const [isShow, setShow] = useState(false);
  const [position, setPosition] = useState({});
  const [hasPermissions, setHasPermissions] = useState(false);
  const [callState, setCallState] = useState(-1);
  const [intervalid, setIntervalid] = useState("");
  const [callObj, setCallObj] = useState("");
  const [partnerData, setPartnerData] = useState({});
  const [isHover, setIsHover] = useState(false);
  const [data, setData] = useState();
  const [remotePeerIdValue, setRemotePeerIdValue] = useState("");
  const [errMsg, setErrMsg] = useState(null);
  const [remoteCamMuted, setRemoteCamMuted] = useState(true);
  const remoteVideoRef = useRef(null);
  const currentUserVideoRef = useRef(null);
  const peerInstance = useRef(null);
  const navigate = useNavigate();

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
      if (total === 0) {
        endcall(remotePeerIdValue);
      }
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
    }
  };

  useEffect(() => {
    globalVar = timer;
  }, [timer]);

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("10:00");
    window.localStorage.setItem("timer", timer);

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setMinutes(deadline.getMinutes() + 10);
    return deadline;
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const tracksClose = () => {
    if (window.localstream) {
      const tracks = window.localstream.getTracks();
      if (tracks && tracks.length) {
        tracks.forEach((track) => {
          //if (track.kind === "video") {
          if (track.enabled) {
            track.stop();
            track.enabled = false;
          }
          // }
        });
      }
      let x;
      x = typeof window.localstream.getTracks()[0];
      if (window.localstream.getTracks().length && x === "MediaStreamTrack") {
        window.localstream.removeTrack(window.localstream.getTracks()[0]);
      }
      x = typeof window.localstream.getTracks()[0];
      if (window.localstream.getTracks().length && x === "MediaStreamTrack") {
        window.localstream.removeTrack(window.localstream.getTracks()[0]);
      }
    }
  };

  const baseurl = "https://api.kiot.io/";
  // const baseurl = "https://staging.kiot.io/";

  const boxStyle = {
    backgroundColor: "white",
    color: "black",
    borderRadius: "20px",
    border: "3px solid rgb(127 219 148)",
    fontSize: "1.2rem",
  };

  const boxStyle1 = {
    height: "4rem",
    width: "4rem",
    borderRadius: "50%",
    backgroundColor: "rgb(215 68 68)",
    color: isHover ? "white" : "black",
    border: "2px solid #008CBA",
    borderColor: "rgb(215 68 68)",
  };

  useEffect(() => {
    let hash = window.location.href.split("=");
    if (hash.length > 1) {
      if (typeof URLSearchParams !== "undefined") {
        const params = new URLSearchParams(
          new URL(window.location.href).search
        );
        params.delete("qr");
        window.history.pushState({}, document.title, "/" + params.toString());
      }
      window.localStorage.setItem("qr", hash[1]);
      let connection_id = checkCookie();
      // console.log(connection_id);
      axios
        .get(
          `${baseurl}peerjs/video_stream?kd_id=` +
            hash[1] +
            `&connection_id=` +
            connection_id
        )
        .then((response) => {
          setLoading(false);
          if (response.data.block_call) {
            navigate("/endcall", {
              state: { block_call: response.data.block_call },
            });
          } else if (response.data.user_busy) {
            navigate("/endcall", {
              state: { user_busy: response.data.user_busy },
            });
          } else {
            createPeer(connection_id);
            //below code is only for testing
            // let x = response.data;
            // x.qr_meta = {
            //   // usecase: "LOST_AND_FOUND_TAG",
            //   // usecase: "VIDEO_DOORBELL",
            //   // usecase: "PET_TAG",
            //   usecase: "LUGGAGE_TAG",
            //   pet_name: "Kukka",
            //   vehicle_number: "TS07 JV 2929",
            //   message:
            //     "Contact me if I lost my weird color bag. I will give you a reward. Thanks!",
            //   pet_type: "dog",
            //   // item_name: "Water Bottle",
            //   item_name: "Mokobara bag",
            // };
            setData(response.data);
            let logo = "";
            let theme = {
              light: 0,
              gold: "#A3C84F",
              gold_rgb: "163, 200, 79",
              gold_tint: "#A3C84F",
              gold_shade: "#A3C84F",
              orange: "#A3C84F",
              orange_rgb: "163, 200, 79",
              orange_tint: "#A3C84F",
              orange_shade: "#A3C84F",
            };
            console.log(logo, "Hello", theme);
            setPartnerData({ logo: logo, theme: theme });
          }
          //setRemotePeerIdValue('abcdefghij');//response.data.connection_id);
          //callPeer('abcdefghij')
        })
        .catch((err) => {
          setErrMsg(err?.response?.data?.message);
          setLoading(false);
          setCallState(-2);
        });
    } else {
      setLoading(false);
      setCallState(-2);
    }
  }, []);

  const checkForPermissions = () => {
    hasMediaPermissions().then((hasPermissions) => {
      if (hasPermissions) {
        setHasPermissions(true);
      } else {
        askForPermissions();
      }
    });
  };

  function hasMediaPermissions() {
    if ("geolocation" in navigator) {
      return navigator.permissions
        .query({ name: "camera" })
        .then((result) => {
          if (result.state === "granted") {
            return navigator.permissions.query({ name: "microphone" });
          } else {
            return Promise.reject();
          }
        })
        .then((result) => {
          if (
            data.qr_meta.usecase === USECASES.VIDEO_DOORBELL ||
            !data.qr_meta
          ) {
            navigator.geolocation.getCurrentPosition((position) => {
              setPosition(position);
              return result.state === "granted";
            });
          } else {
            return result.state === "granted";
          }
        })
        .catch(() => false);
    } else {
      return false;
    }
  }

  function askForPermissions() {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      function (position) {
        //console.log(position);
        setPosition(position);
        // Do something with the user's position (position.coords.latitude and position.coords.longitude)
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then((stream) => {
            setLoading(false);
            console.log(stream);
            setHasPermissions(true);
          })
          .catch((err) => {
            setLoading(false);
            setCallState(-2);
            console.error(`you got an error: ${err}`);
            //askForPermissions();
          });
      },
      function (error) {
        setLoading(false);
        setCallState(-2);
        console.error(`you got an error: ${error}`);
        // Handle errors (e.g., permission denied or geolocation not available)
      }
    );
  }

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371; // Radius of the Earth in kilometers (you can also use 3959 miles for miles)

    // Convert latitude and longitude from degrees to radians
    const radLat1 = (Math.PI * lat1) / 180;
    const radLon1 = (Math.PI * lon1) / 180;
    const radLat2 = (Math.PI * lat2) / 180;
    const radLon2 = (Math.PI * lon2) / 180;

    // Haversine formula
    const dLat = radLat2 - radLat1;
    const dLon = radLon2 - radLon1;
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c * 1000; // Distance in mtrs

    return distance;
  }

  const callCheck = () => {
    if (data.co_ordinates) {
      let distance = calculateDistance(
        data.co_ordinates.lati,
        data.co_ordinates.longi,
        position.coords.latitude,
        position.coords.longitude
      );
      if (distance > 1500) {
        setCallState(-2);
        setErrMsg("Too far away from Home.");
      } else {
        callNow();
      }
    } else {
      callNow();
    }
  };

  const callNow = () => {
    let hash = window.localStorage.getItem("qr"); //window.location.pathname.slice(1);
    console.log(hash);
    getMedia();
    setCallState(1);
    console.log("Hello", remotePeerIdValue);
    let connection_id = getCookie("connection_id");
    let session_id = data.session_id;
    axios
      .get(
        `${baseurl}peerjs/video_call?kd_id=` +
          hash +
          `&session_id=` +
          session_id +
          `&connection_id=` +
          connection_id
      )
      .then((response) => {
        //  setTimeout(()=>{
        if (response.data.block_call) {
          navigate("/endcall", {
            state: { block_call: response.data.block_call },
          });
        } else if (response.data.user_busy) {
          navigate("/endcall", {
            state: { user_busy: response.data.user_busy },
          });
        } else {
          setRemotePeerIdValue(response.data.connection_id);
          callPeer(response.data.connection_id);
        }
      })
      .catch((err) => {
        setErrMsg(err?.response?.data?.message);
        //setLoading(false);
        setCallState(-2);
      });
  };

  function setCookie(cname, cvalue) {
    let date = new Date();
    date.setTime(date.getTime() + 399 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + date.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  function checkCookie() {
    let user = getCookie("connection_id");
    if (user && user.length === uuid().length) {
      return user;
    } else {
      user = uuid();
      if (user !== "" && user != null) {
        setCookie("connection_id", user);
        return user;
      }
    }
  }

  const createPeer = (connection_id) => {
    const peer = new Peer(connection_id, {
      debug: 3,
      host: "peer.kiot.io",
      path: "/peerjs/myQRapp",
      secure: true,
      config: {
        iceServers: freeice(),
      },
    });
    // setLoading(false);
    peer.on("open", (id) => {
      // setPeerId(id)
    });
    peer.on("error", (err) => {
      console.log(peer, "PEER", err, "ERROR");
    });
    peer.on("connection", (conn) => {
      conn.on("iceStateChanged", () => {
        console.log("iceStateChanged");
        conn.close();
      });
      conn.on("error", (err) => {
        console.log(err, "CONN ERROR");
      });
      conn.on("close", () => {
        console.log("conn close event");
        // handlePeerDisconnect();
        tracksClose();
        setCallState(-1);
        goToEndpage();
      });
      conn.on("data", function (raw_data) {
        console.log("data received", raw_data);
        try {
          let data = JSON.parse(raw_data);
          if (data && (data.cam_mute === true || data.cam_mute === false)) {
            setRemoteCamMuted(data.cam_mute);
            console.log(remoteCamMuted, data.cam_mute, "chudu");
          }
        } catch (e) {
          console.log("Invalid data", raw_data);
        }
      });
    });
    peerInstance.current = peer;
  };

  const endcall = (remotePeerId) => {
    console.log(intervalid, "Interval");
    if (intervalid !== "" || window.localStorage.getItem("intervalid") !== "") {
      clearInterval(intervalid);
      clearInterval(parseInt(window.localStorage.getItem("intervalid")));
      setIntervalid("");
      window.localStorage.setItem("intervalid", "");
    }
    let hash = window.localStorage.getItem("qr"); //window.location.pathname.slice(1);
    let connection_id = getCookie("connection_id");
    let session_id = data.session_id;
    let x = timer.split(":");
    let z = parseInt(x[1]) + parseInt(x[0]) * 60;
    let remainingTime = 600 - z;
    let minutes = parseInt((remainingTime / 60).toFixed());
    let seconds = remainingTime % 60;
    let duration =
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds);
    let call_answered = true;
    if (callState === 1) {
      call_answered = false;
    }
    axios
      .get(
        `${baseurl}peerjs/end_video_call?kd_id=` +
          hash +
          `&session_id=` +
          session_id +
          `&connection_id=` +
          connection_id +
          `&duration=` +
          duration +
          `&call_answered=` +
          call_answered
      )
      .then((response) => {});

    if (callObj) {
      callObj.close();
    }
    tracksClose();
    if (currentUserVideoRef && currentUserVideoRef.current) {
      currentUserVideoRef.current.srcObject = null;
    }
    remoteVideoRef.current.srcObject = null;
    //  peerInstance.current.disconnect();
    // peerInstance.current.destroy('9876543210');
    navigate("/endcall", {
      state: { data: data, timer: timer, callState: callState },
    });
    setCallState(-1);
  };

  const getMedia = () => {
    navigator.getUserMedia = navigator.getUserMedia.bind(navigator);

    navigator.getUserMedia(
      { video: true, audio: true },
      (mediaStream) => {
        if (currentUserVideoRef.current) {
          currentUserVideoRef.current.srcObject = mediaStream;
          window.localstream = mediaStream;
          let myVideo = document.getElementById("my-video");
          if (myVideo) {
            myVideo.volume = 0;
          }
          currentUserVideoRef.current.addEventListener("canplay", () => {
            currentUserVideoRef.current.play();
            currentUserVideoRef.current.removeEventListener(
              "canplay",
              () => {}
            );
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
    console.log(remoteVideoRef, "initial");
  };

  const callPeer = (id1) => {
    if (intervalid === "") {
      setStartTime(new Date());
      let date = new Date();
      let id = setInterval(() => {
        console.log("asdsad", intervalid, "IntervalId");
        let now = new Date();
        let r = now - date;
        if (new Date(r).getSeconds() >= 45) {
          clearInterval(intervalid);
          clearInterval(parseInt(window.localStorage.getItem("intervalid")));
          setIntervalid("");
          window.localStorage.setItem("intervalid", "");
          goToEndpage(true);
        } else {
          setCallState(1);
          call(id1);
        }
      }, 5000);

      console.log(id, "setinterval");
      window.localStorage.setItem("intervalid", id);
      setIntervalid(id);
    }
  };

  const call = (remotePeerId) => {
    if (callState !== 2) {
      var constraints = {
        mandatory: {
          OfferToReceiveAudio: true,
          OfferToReceiveVideo: true,
        },
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
      };
      const call = peerInstance.current.call(
        remotePeerId,
        window.localstream,
        constraints
      );
      setCallObj(call);
      call.on("stream", (remoteStream) => {
        console.log("Receiving Stream", intervalid, remoteStream);
        window.remoteStream = remoteStream;
        if (
          intervalid !== "" ||
          window.localStorage.getItem("intervalid") !== ""
        ) {
          clearInterval(intervalid);
          clearInterval(parseInt(window.localStorage.getItem("intervalid")));
          setIntervalid("");
          window.localStorage.setItem("intervalid", "");
        }
        if (callState !== 2) {
          setCallState(2);
        }
        remoteVideoRef.current.addEventListener("canplay", () => {
          console.log(remoteVideoRef, "Yello");
          if (remoteVideoRef && remoteVideoRef.current) {
            remoteVideoRef.current.play();
            remoteVideoRef.current.removeEventListener("canplay", () => {});
          }
        });
        remoteVideoRef.current.onerror = (e) => {
          console.error("Video error:", e);
        };

        setTimeout(() => {
          remoteVideoRef.current.srcObject = remoteStream;
        }, 1000);

        console.log(remoteStream, "remote", remoteVideoRef.current);

        clearTimer(getDeadTime());

        const conn = peerInstance.current.connect(remotePeerId);
        conn.on("open", function () {
          // here you have conn.id
          conn.send("hi!");
        });
        conn.on("data", (data) => {
          // here you have conn.id
          console.log(data);
        });
      });
      call.on("close", () => {
        console.log("call close event");
        tracksClose();
        setCallState(-1);
        goToEndpage();
      });
    }
  };

  const goToEndpage = (no_answer) => {
    let hash = window.localStorage.getItem("qr"); //window.location.pathname.slice(1);
    let connection_id = getCookie("connection_id");
    let session_id = data.session_id;
    let x = globalVar.split(":");
    let z = parseInt(x[1]) + parseInt(x[0]) * 60;
    let remainingTime = 600 - z;
    let minutes = parseInt((remainingTime / 60).toFixed());
    let seconds = remainingTime % 60;
    let call_answered = true;
    if (callState === 1 || no_answer) {
      call_answered = false;
    }
    tracksClose();
    let duration =
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds);
    axios
      .get(
        `${baseurl}peerjs/end_video_call?kd_id=` +
          hash +
          `&session_id=` +
          session_id +
          `&connection_id=` +
          connection_id +
          `&duration=` +
          duration +
          `&call_answered=` +
          call_answered
      )
      .then((response) => {});
    navigate("/endcall", {
      state: {
        data: data,
        timer: globalVar,
        no_answer: no_answer ? no_answer : null,
      },
    });
  };

  return (
    <div className="App App-header">
      {isLoading ? (
        <div>
          <div className="loader"></div>
          <div className="mt-1rem"> Loading... </div>
        </div>
      ) : (
        <div>
          {callState === -2 ? (
            <Page404 msg={errMsg} />
          ) : hasPermissions ? (
            <div>
              {/* <input type="text" value={remotePeerIdValue} onChange={e => setRemotePeerIdValue(e.target.value)} /> */}
              {callState !== 0 && (
                <div>
                  {callState === -1 && (
                    <>
                      {(data &&
                        data.qr_meta &&
                        data.qr_meta.usecase === USECASES.VIDEO_DOORBELL) ||
                      (data && !data.qr_meta) ? (
                        <>
                          <div>
                            <img
                              src={
                                `https://kiot1.s3.ap-south-1.amazonaws.com/partner_logos/` +
                                data.partner_name +
                                `.png`
                              }
                              className="logo"
                              alt="Logo"
                            ></img>
                            <div style={{ padding: "1rem" }}>
                              Home: {data.home_name}
                            </div>
                            <button
                              style={boxStyle}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                              className="button button2"
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                  fontSize: "2rem",
                                }}
                              ></i>
                              <span
                                style={{
                                  top: "-0.2rem",
                                  position: "relative",
                                }}
                              >
                                Call
                              </span>
                            </button>
                          </div>
                        </>
                      ) : null}
                      {data &&
                      data.qr_meta &&
                      data.qr_meta.usecase === USECASES.PET_TAG ? (
                        <>
                          <div className="m-1-5rem">
                            <div className="font-6rem">
                              {data.qr_meta.pet_type === "dog" ? (
                                <>🐶</>
                              ) : data.qr_meta.pet_type === "cat" ? (
                                <>😼</>
                              ) : null}
                            </div>
                            <div className="pet-name">
                              Hi, My name is {data.qr_meta.pet_name}
                            </div>
                            {data.qr_meta.message && (
                              <div className="pet-message">
                                My owner says:
                                <div>"{data.qr_meta.message}"</div>
                              </div>
                            )}

                            <div className="pet-contact">
                              Please contact my owner 🥺
                            </div>
                            <button
                              style={boxStyle}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                              className="button button2 button-pet"
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                  fontSize: "2rem",
                                }}
                              ></i>
                              <span
                                style={{
                                  top: "-0.2rem",
                                  position: "relative",
                                }}
                              >
                                Call
                              </span>
                            </button>
                          </div>
                        </>
                      ) : null}
                      {data &&
                      data.qr_meta &&
                      data.qr_meta.usecase === USECASES.VEHICLE_TAG ? (
                        <>
                          <div className="m-1-5rem">
                            <div className="font-6rem">🚘</div>
                            <div>You have scanned the QR of</div>
                            <div className="pet-name">
                              {data.qr_meta.vehicle_number}
                            </div>
                            {data.qr_meta.message && (
                              <div className="pet-message">
                                Vehicle owner says:
                                <div>"{data.qr_meta.message}"</div>
                              </div>
                            )}

                            <div className="pet-contact">
                              Tap below to call the owner
                            </div>
                            <button
                              style={boxStyle}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                              className="button button2 button-pet"
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                  fontSize: "2rem",
                                }}
                              ></i>
                              <span
                                style={{
                                  top: "-0.2rem",
                                  position: "relative",
                                }}
                              >
                                Call
                              </span>
                            </button>
                          </div>
                        </>
                      ) : null}
                      {data &&
                      data.qr_meta &&
                      data.qr_meta.usecase === USECASES.LOST_AND_FOUND_TAG ? (
                        <>
                          <div className="m-1-5rem">
                            <div className="font-6rem">🔍</div>
                            <div>
                              This is a lost & found tag, please call the owner
                              to return their {" "}
                              <span className="item-name-tag">
                                {data.qr_meta.item_name || "item"}
                              </span>
                            </div>
                            {data.qr_meta.message && (
                              <div className="pet-message">
                                Item owner says:
                                <div>"{data.qr_meta.message}"</div>
                              </div>
                            )}

                            <div className="pet-contact">
                              Tap below to call the owner
                            </div>
                            <button
                              style={boxStyle}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                              className="button button2 button-pet"
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                  fontSize: "2rem",
                                }}
                              ></i>
                              <span
                                style={{
                                  top: "-0.2rem",
                                  position: "relative",
                                }}
                              >
                                Call
                              </span>
                            </button>
                          </div>
                        </>
                      ) : null}
                      {data &&
                      data.qr_meta &&
                      data.qr_meta.usecase === USECASES.LUGGAGE_TAG ? (
                        <>
                          <div className="m-1-5rem">
                            <div className="font-6rem">🧳</div>
                            <div>
                              This is a luggage tag, please call the owner
                              to return their {" "}
                              <span className="item-name-tag">
                                {data.qr_meta.item_name || "lugagge"}
                              </span>
                            </div>
                            {data.qr_meta.message && (
                              <div className="pet-message">
                                Luggage owner says:
                                <div>"{data.qr_meta.message}"</div>
                              </div>
                            )}

                            <div className="pet-contact">
                              Tap below to call the owner
                            </div>
                            <button
                              style={boxStyle}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                              className="button button2 button-pet"
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                  fontSize: "2rem",
                                }}
                              ></i>
                              <span
                                style={{
                                  top: "-0.2rem",
                                  position: "relative",
                                }}
                              >
                                Call
                              </span>
                            </button>
                          </div>
                        </>
                      ) : null}

                    </>
                  )}
                </div>
              )}
              <div>
                {currentUserVideoRef ? (
                  <video
                    style={{
                      // border: '1px solid',
                      borderRadius: "15px",
                      objectFit: "cover",
                      position: "absolute",
                      height: "25vh",
                      width: "200px",
                      bottom: "5rem",
                      right: "1px",
                      display: callState !== -1 ? "block" : "none",
                    }}
                    ref={currentUserVideoRef}
                    id="my-video"
                    muted="muted"
                    playsInline
                    webkit-playsinline="true"
                  />
                ) : null}
              </div>
              <div>
                <video
                  style={{
                    height: "100vh",
                    width: "100%",
                    objectFit: "cover",
                    display: callState === 2 ? "block" : "none",
                  }}
                  ref={remoteVideoRef}
                  playsInline
                  webkit-playsinline="true"
                />
                {callState === 2 && (
                  <div
                    style={{
                      background: "rgb(179 179 179 / 0.5)",
                      fontSize: "1.3rem",
                      top: "0vh",
                      /* left: 50vw; */
                      position: "absolute",
                      width: "100%",
                      textAlign: "center",
                      padding: "0.4rem 0 0.35rem 0",
                    }}
                  >
                    {timer}
                  </div>
                )}
                {callState !== -1 && callState !== 2 ? (
                  <div
                    style={{
                      background: "#ffffff12",
                      height: "100vh",
                      width: "400px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        border: "5px solid #f3f3f3",
                        borderRadius: "50%",
                        borderTop: "5px solid #3498db",
                        borderTopColor: partnerData.theme.gold,
                        width: "50px",
                        height: "50px",
                        animation: "spin 2s linear infinite",
                      }}
                    ></div>
                    <div> Calling... </div>
                  </div>
                ) : null}
                {callState !== -1 && (
                  <div
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      position: "absolute",
                      bottom: "0px",
                      width: "100%",
                    }}
                  >
                    <button
                      style={boxStyle1}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => endcall(remotePeerIdValue)}
                      className="button button2"
                    >
                      <i
                        className="fa fa-phone"
                        style={{
                          position: "relative",
                          right: "0.8rem",

                          fontSize: "2rem",
                        }}
                      ></i>
                    </button>
                  </div>
                )}
              </div>{" "}
            </div>
          ) : (
            <div style={{ overflow: "scroll !important" }}>
              <div
                style={{
                  fontWeight: 300,
                  fontSize: "2rem",
                  margin: "0px 3rem",
                }}
              >
                Permissions
              </div>
              <div
                style={{
                  height: "20rem",
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                }}
              >
                <div
                  style={{
                    margin: "0 auto",
                    border: "3px solid rgb(128, 128, 128)",
                    borderRadius: "15px",
                    /* justify-content: center; */
                    /* text-align: center; */
                    height: "100%",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      height: "30%",
                    }}
                  >
                    <i
                      style={{
                        top: "30%",
                        position: "relative",
                        fontSize: "3rem",
                        color: "rgb(128, 128, 128)",
                      }}
                      className="fa fa-map-marker"
                    ></i>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      height: "30%",
                    }}
                  >
                    <i
                      style={{
                        top: "30%",
                        position: "relative",
                        fontSize: "3rem",
                        color: "rgb(128, 128, 128)",
                      }}
                      className="fa fa-camera"
                    ></i>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      height: "30%",
                    }}
                  >
                    <i
                      style={{
                        top: "20%",
                        position: "relative",
                        fontSize: "3rem",
                        color: "rgb(128, 128, 128)",
                      }}
                      className="fa fa-microphone"
                    ></i>
                  </div>
                </div>
              </div>
              <div style={{ fontSize: "1rem", margin: "0 3rem" }}>
                Please allow location, camera and microphone permissions for
                Video calling. Location permission is to secure call connection.
              </div>
              <div style={{ paddingTop: "2rem" }}>
                <button
                  style={boxStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => checkForPermissions()}
                  className="button button2"
                >
                  Allow Permissions
                </button>
                <div
                  onClick={() => {
                    if (!isShow) {
                      var x = document.getElementsByTagName("BODY")[0];
                      x.style.overflow = "scroll";
                    }
                    setShow(!isShow);
                  }}
                  style={{
                    paddingTop: "1rem",
                    fontWeight: "500",
                    fontSize: "1rem",
                    color: "rgb(127, 219, 148)",
                    textDecoration: "underline",
                  }}
                >
                  Click for Instructions to Reset Permissions
                </div>
              </div>
              <div
                style={{
                  margin: "1rem 2.5rem",
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                {isShow && (
                  <>
                    <div>Android</div>
                    <li>
                      Tap on address box lock icon on top and select permissions
                      to reset.
                    </li>
                    <div>IOS</div>
                    <li>Kindly refresh page or scan the QR again to reset</li>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MainApp;
